import {FC, Fragment, useEffect, useState} from 'react';

type ParagraphData = {
    type: string
    ,fontSize: string
    ,content: string
}


export const InfoBox: FC<{totalMinted?: number}> = (props) => {

    const [boxContent, setBoxcontent] = useState<JSX.Element[]>([]);

    useEffect(() => {
        fetch("./page-content.json")
            .then((res) => res.json())
            .then((data) => {
                const paragraphs: JSX.Element[] = [];
                data.infoBlock1.forEach(function (pData: ParagraphData) {
                    const phtml = "Test";
                    paragraphs.push(
                        renderParagraph(pData)
                        )
                })
                setBoxcontent(paragraphs);
                console.log(boxContent);
            });
        }, []);

    const renderParagraph = (pData: ParagraphData) => {
        switch(pData.type) {
        case "hr":
            return (<hr className="my-4"/>)
        case "mintCounter":
            return (<p style={{fontSize: pData.fontSize}}>    
                        {pData.content.split('{}')[0]} {Math.ceil((props.totalMinted ?? 0) / 314)}{pData.content.split('{}')[1]} 
                    </p>)
        default: 
            return (
                <p
                style={{fontSize: pData.fontSize}}
                >
                    {pData.content}
                </p>
            )

        }
    }


    

    return (
        <Fragment>

        <div id='info-block' className="bg-transparent p-4 rounded m-3" style={{textAlign: "center"}} >

            {boxContent}
                
        </div>


        </Fragment>
    );
}

export default InfoBox