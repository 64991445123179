import React from "react"

const Footer = (): JSX.Element => <footer className="page-footer link-dark font-small p-3">

    <div className="footer-copyright text-center py-1">Contact: &nbsp;
        <a href="mailto:hello@autonomylab.xyz" className="link-dark">hello@autonomylab.xyz</a>
    </div>
    <div className="footer-copyright text-center py-1">Twitter: &nbsp;
        <a href="https://twitter.com/WavepoolsNFT" className="link-dark">@WavepoolsNFT</a>
    </div>
    <div className="footer-copyright text-center py-1">© Copyright 2024: &nbsp;
        <a href="https://web3.autonomylab.xyz/" className="link-dark">autonomylab.xyz</a>
    </div>

</footer>

export default Footer