import React, { FC, useState, useEffect, Fragment } from 'react';
import { useConnection } from '@solana/wallet-adapter-react';

import * as anchor from "@coral-xyz/anchor";
import { getCollection } from '../js/getCollection'
import { IDL, WavepoolsCreator } from "../idl/wavepools_creator"



import { Header } from './Header';
import { Gallery } from './Gallery';
import InfoBlock from './InfoBlock';
import Footer from './Footer';
import { collectionDetailsToggleBeet } from '@metaplex-foundation/mpl-token-metadata';

const BN = require('bn.js');


type MintStatus = {
    isSaleActive: boolean,
    salePrice?: number,
    totalMinted: number,
    status1: typeof BN,
    status2: typeof BN,
    status3: typeof BN
};

interface NFT {
    name?: string;
    mint?: string;
    owner?: string;
};

const IS_TEST_MODE = false;


const Home: FC = () => {

    

    const { connection } = useConnection();
    const program = new anchor.Program<WavepoolsCreator>(IDL, new anchor.web3.PublicKey(process.env.REACT_APP_PROGRAM_ID || ""), { connection })
    const collectionAddress = new anchor.web3.PublicKey(process.env.REACT_APP_COLLECTION_ADDRESS || "")
    

    const [mintStatusData, setMintStatusData] = useState<MintStatus>({isSaleActive: false, salePrice: undefined, totalMinted: 0, status1: new BN(0), status2: new BN(0), status3: new BN(0)});
    const [nftData, setNFTData] = useState<NFT[]>([]);



    
    const getMintStatusData = (index: number) => {
        if(index <= 128){
         return mintStatusData.status1.testn(index-1);
        } else if (index <= 256){
         return mintStatusData.status1.testn(index-1);
        } else if (index <= 314){
         return mintStatusData.status1.testn(index-1);
        } 
 
        return 0;
     }
    
    
    
    const getMintStatusAccountData = async () => {
        console.log(`getting data from ${process.env.REACT_APP_MINT_STATUS_ADDRESS}`);
        console.log(program.account.mintStatus.fetch(process.env.REACT_APP_MINT_STATUS_ADDRESS || ""));
        let tx = await program.account.mintStatus.fetch(process.env.REACT_APP_MINT_STATUS_ADDRESS || "");
        //console.log(tx)
        let data = await getCollection(connection, collectionAddress);
        setNFTData(data);
        setMintStatusData({isSaleActive: tx.isOnSale, salePrice: tx.salePrice / 1000000000, totalMinted: nftData.length, status1: tx.status1, status2: tx.status2, status3: tx.status3});
        //console.log(tx);
        console.log(mintStatusData);



    }

    useEffect(() => {
        getMintStatusAccountData();
        
    }, []);

    return (<Fragment>
            <Header />
            <InfoBlock totalMinted={nftData.length}/>
            <Gallery
                program={program}
                connection={connection}
                getMintStatusAccountData={getMintStatusAccountData}
                mintStatusData={mintStatusData}
                nftData={nftData}
            />
            <Footer />
            </Fragment>
    );
};


export default Home;

