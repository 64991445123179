import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from './App';
import Animation from './pages/Animation';
import Admin from './pages/Admin';
import 'bootstrap/dist/css/bootstrap.min.css';
//import './index.css';
import './css/overrides.css';
import reportWebVitals from './reportWebVitals';
ReactDOM.render(


    <React.StrictMode>
        <BrowserRouter>
      <Routes>
          <Route index element={<App />} />
          <Route path="animation/:id" element={<Animation />} />
          <Route path="do-admin-stuff" element={<Admin />} />
          <Route path="*" element={<App />} />
      </Routes>
    </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
