import { ConfirmedSignatureInfo, Connection, PublicKey } from "@solana/web3.js";
import fs from "fs";
import {
  Metadata,
  PROGRAM_ADDRESS as metaplexProgramId,
} from "@metaplex-foundation/mpl-token-metadata";
import { sign } from "crypto";

interface NFT {
  name?: string;
  mint?: string;
  owner?: string;
}


export const getCollection = async (connection: Connection, collection_id: PublicKey) => {
  // Get command line arguments
  const args = process.argv.slice(2, 4);

  // let connection = new Connection(
  //   "https://api.metaplex.com",
  //   "confirmed"
  // );


  console.log("Getting signatures...");
  let allSignatures: ConfirmedSignatureInfo[] = [];

  // This returns the first 1000, so we need to loop through until we run out of signatures to get.
  let signatures = await connection.getSignaturesForAddress(collection_id);
  if(signatures.length == 0) return [];
  allSignatures.push(...signatures);
  do {
    let options = {
      before: signatures[signatures.length - 1].signature,
    };
    signatures = await connection.getSignaturesForAddress(
      collection_id,
      options
    );
    allSignatures.push(...signatures);
  } while (signatures.length > 0);

  console.log(`Found ${allSignatures.length} signatures`);
  let metadataAddresses: PublicKey[] = [];
  let mintAddresses: NFT[] = [];

  console.log("Getting transaction data...");
  const promises = allSignatures.map((s) =>
    connection.getTransaction(s.signature)
  );
  const transactions = await Promise.all(promises);

  //console.log(allSignatures);

  //console.log("HERE");
 //console.log(await connection.getTransaction("2P9oMtsbWEFsv4uYCrQmpRobmbzdBhMsDTredAKfkbEpf6yxcAEbUEN4ixZRRoJyAx1JQjFcc4akVWgeNqHUDetp"));
  console.log("Parsing transaction data...");
  for (const tx of transactions) {
    if (tx) {
      //console.log(tx);
      let programIds = tx!.transaction.message
        .programIds()
        .map((p) => p.toString());
      let accountKeys = tx!.transaction.message.accountKeys.map((p) =>
        p.toString()
      );
      // Only look in transactions that call the Metaplex token metadata program
      if (accountKeys.includes(metaplexProgramId) && (tx.meta?.innerInstructions)) {
        //console.log(tx);
        // Go through all instructions in a given transaction
        for (const ix of tx.meta.innerInstructions) {
          for (const inx of ix.instructions){
          // Filter for setAndVerify or verify instructions in the Metaplex token metadata program
          if (
            (inx.data == "4xY" || // VerifyCollection instruction
            inx.data == "S" || // SetAndVerifyCollection instruction
            inx.data == "X" || // VerifySizedCollectionItem instruction
            inx.data == "Z") && // SetAndVerifySizedCollectionItem instruction
            accountKeys[inx.programIdIndex] == metaplexProgramId
          ) {
            let metadataAddressIndex = inx.accounts[2];
            let metadata_address =
              tx!.transaction.message.accountKeys[metadataAddressIndex];
              //console.log(metadata_address.toString())
            metadataAddresses.push(metadata_address);
          }
          }
        }
      }
    }
  }
console.log("Getting Metadata account data...")
  const promises2 = metadataAddresses.map((a) => connection.getAccountInfo(a));
  const metadataAccounts = await Promise.all(promises2);
  //console.log(metadataAccounts)
  for (const account of metadataAccounts) {
    if (account) {
      let metadata = await Metadata.deserialize(account!.data);
      //console.log(metadata)
      mintAddresses.push({name: metadata[0].data.name.split('\u0000')[0], mint:metadata[0].mint.toBase58(), owner: ""});
    }
  }
  //console.log(mintAddresses);
  //let mints: string[] = Array.from(mintAddresses);
  //console.log(mintAddresses)
  return mintAddresses;
}
