import React, { Fragment, useCallback, useState } from 'react';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';

import * as anchor from "@coral-xyz/anchor";
import { IDL, WavepoolsCreator } from "../idl/wavepools_creator"
import Button from 'react-bootstrap/Button'


import { Header } from './Header';
import { collectionDetailsToggleBeet } from '@metaplex-foundation/mpl-token-metadata';

const BN = require('bn.js');



const FlipSale: React.FC = () => {

    const [priceValue, setPrice] = useState(0.1);

    const { publicKey, sendTransaction } = useWallet();
    const { connection } = useConnection();
    const program = new anchor.Program<WavepoolsCreator>(IDL, new anchor.web3.PublicKey(process.env.REACT_APP_PROGRAM_ID || ""),{ connection })


    const flipSaleState = useCallback(async () =>{

        console.log("Attempting to flipSaleState");
        console.log(publicKey?.toString());
        console.log(process.env.REACT_APP_MINT_STATUS_ADDRESS);

   
    const initTX = await program.methods.toggleSale()
      .accounts({
        authority: publicKey || "",
        mintStatus: new anchor.web3.PublicKey(process.env.REACT_APP_MINT_STATUS_ADDRESS || ""),
    }).transaction();

    console.log(initTX);
   
    try{
        const sig = await sendTransaction(initTX, connection)
        console.log(sig);
        await connection.confirmTransaction(sig,'processed');
        console.log("processed")

        await connection.confirmTransaction(sig,'confirmed');
        console.log("confirmed")
    }catch (e: any){
        console.log(e);
    }

    }, [publicKey, sendTransaction, connection]);


    const setSalePrice = useCallback(async (event) => {
        event.preventDefault()
        //let priceFloatValue = parseFloat(priceValue);
        
        console.log(`Attempting to setSalePrice to ${priceValue}`);
        console.log(publicKey?.toString());
        console.log(process.env.REACT_APP_MINT_STATUS_ADDRESS);

        // if (!priceFloatValue) {
        //     console.log(`${priceValue} is not a valid price`)   
        //     return;
        // }
   
    const initTX = await program.methods.setPrice(priceValue* 1000000000)
      .accounts({
        authority: publicKey ?? "",
        mintStatus: new anchor.web3.PublicKey(process.env.REACT_APP_MINT_STATUS_ADDRESS || ""),
    }).transaction();

    console.log(initTX);
   
    try{
        const sig = await sendTransaction(initTX, connection)
        console.log(sig);
        await connection.confirmTransaction(sig,'processed');
        console.log("processed")

        await connection.confirmTransaction(sig,'confirmed');
        alert("confirmed")
    }catch (e: any){
        console.log(e);
    }

    }, [priceValue, publicKey, sendTransaction, connection]);


    return (
            <Fragment>
                <Header />

            <Button 
                onClick={flipSaleState}
                >Flip Sale State</Button>
            <form onSubmit={setSalePrice}>
                <label>Set Price (SOL)
                <input name="price" type="number" step="0.01" value={priceValue} onChange={(e) => setPrice(parseFloat(e.target.value))} />
                <input type="submit" value="submit" />
                </label>
            </form>
            </Fragment>
    );
};

export default FlipSale;



