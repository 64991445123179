import {WalletMultiButton} from '@solana/wallet-adapter-react-ui';

import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'

import { FC } from 'react';

import '../css/wallet.css';


export const Header: FC = () => {

    

    return (
        
        
        <Navbar bg="primary" expand="lg" fixed="top">
            <Container fluid>
            <Navbar.Brand 
                style={{fontWeight: 'bold', fontSize: '2em', color: "rgb(33,37,41)"}}
                href="/">
                    <img 
                        src="https://web3.autonomylab.xyz/img/56.png"
                        width = "50"
                        height = "50"
                        className="d-inline-block align-top"
                        alt=""
                        style = {{marginRight: '0.3em'}}
                        
                    />
                    Wavepools
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
                <Navbar.Text>
                    <WalletMultiButton className="wallet-button"></WalletMultiButton>  
                </Navbar.Text>
            </Navbar.Collapse>

            </Container>
        </Navbar>
    );
};

/*
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
                <Nav.Link 
                    href="info"
                    className="d-none"
                    style={{fontSize: '1.25em'}}
                >
                    Info</Nav.Link>
    
                <Nav.Link 
                    className="d-none"
                    href="https://testnets.opensea.io/collection/wavepools"
                    target='_blank'
                    style={{fontSize: '1.25em'}}
                >OpenSea</Nav.Link>

            </Nav>

            </Navbar.Collapse>

    */

