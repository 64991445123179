import React from 'react'
import WavePoolAnimated from '../js/MoireGeneratorP5JSRev2'
import { propTypes } from 'react-bootstrap/esm/Image';
import { useParams } from "react-router-dom";

type RouteParams = {
    id: string
}


const Animation: React.FC = () => {

    const params = useParams<RouteParams>();

    return (
        <WavePoolAnimated poolID={params.id} style={{position: 'absolute', top: '0'}} />
    );
};

export default Animation;