import React, { FC, Fragment, useState, useEffect, useCallback } from 'react';
//import { useSearchParams } from "react-router-dom";
//import {usePromiseState, PromiseStatus} from "react-promise-state-hook";
import * as anchor from "@coral-xyz/anchor";
import { IDL, WavepoolsCreator } from "../idl/wavepools_creator"

import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal'

import { WavePoolView } from './WavePoolView';
import { ConfirmedSignatureInfo } from '@solana/web3.js';

import wavepoolData from '../js/wavepoolData.json'
const BN = require('bn.js');


type MintStatus = {
    isSaleActive: boolean,
    salePrice?: number,
    totalMinted: number,
    status1: typeof BN,
    status2: typeof BN,
    status3: typeof BN
};

interface NFT {
    name?: string;
    mint?: string;
    owner?: string;
};


type GalleryProps = {
    program: anchor.Program<WavepoolsCreator>,
    connection: anchor.web3.Connection,
    getMintStatusAccountData: Function,
    mintStatusData: MintStatus,
    nftData: NFT[],
}


export const Gallery: FC<GalleryProps> = (props) => {

    const [showQuantity, setShowQuantity] = useState(25);
    const [overlayID, setOverlayID] = useState(1);
    const [showOverlay, setShowOverlay] = useState(false);
    


    const getMintStatusData = (index: number) => {
       if(index <= 128){
        return props.mintStatusData.status1.testn(index-1);
       } else if (index <= 256){
        return props.mintStatusData.status1.testn(index-1);
       } else if (index <= 314){
        return props.mintStatusData.status1.testn(index-1);
       } 

       return 0;
    }

    

    const onWavePoolViewClick = (poolID: number) => {
        console.log("WavePool "+ poolID +" Clicked");
        setOverlayID(poolID);
        setShowOverlay(true);
    }


    const renderTokenView = (key: number, pool: number, status=0, showButtons=false) => {  
        //console.log(nftData.find((i) => i.name == `Wavepool #${pool}`)?.mint)
        //console.log(wavepoolData[pool]);
        let data = wavepoolData[pool-1]
        let cardColor = data.layers == 3 ? data.color2 :data.color1;
        return(
           <WavePoolView 
                        program={props.program}
                        connection={props.connection}
                        key={key} 
                        wavepoolID={pool}
                        bgColor={data.colorBg}
                        cardColor={cardColor}
                        mint={props.nftData.find((i) => i.name == `WavePool #${pool}`)?.mint || ""}
                        //poolData={pool} 
                        status={getMintStatusData(pool)} 
                        saleIsActive = {props.mintStatusData.isSaleActive}
                        salePriceSol = {props.mintStatusData.salePrice}
                        showButtons={showButtons}
                        clickHandler={onWavePoolViewClick.bind(this)} 
                        mintSuccess={props.getMintStatusAccountData.bind(this)}

                        //mintEthHandler={mintWavepoolEth.bind(this)}
                        //buyPlotHandler={buyWavepoolPlot.bind(this)}
            />
        )
      }

      let pooldivs = [];

      for( let i = 1; i<= showQuantity; i++){
        pooldivs.push(renderTokenView(i, i, 0, false));
      }


       

        // wavePoolData.forEach((pool, i) => {
        //     if(i >= showQuantity) return;
        //     pooldivs.push(renderTokenView(i+1, pool));
        // });
        /*
        // get the owner of the popup only
        let popupowner;
        if( owned[owned.findIndex(p => p.poolID = this.state.showpopup)] != null ){
            popupowner = (owned[owned.findIndex(p => p.poolID = this.state.showpopup)]).owner
        }
        */

    // const getStatus = () => {
    //     if(mintingStatus.status == PromiseStatus.PENDING) return "Minting"
    //     if(plottingStatus.status == PromiseStatus.PENDING) return "Plotting"
    //     return 0;
    // }
    //     let overlayPool = wavePoolData.find(pool => pool.poolID == overlayID)
        return (
            <Fragment>
                <Container fluid
                    style= {{marginTop: "10px"}}
                    className="mx-auto" >
                    <Row 
                        className="mx-auto"
                        style= {{gap: "1.0rem"}}
                    >
                    {pooldivs}
                    </Row>
                </Container>

                <div className="d-grid gap-1 p-4" >
                    <Button 
                        variant="dark"
                        onClick={() => setShowQuantity(Math.min(showQuantity + 25, 314))}
                    >
                        Show More
                    </Button>
                    <Button 
                        variant="dark"
                        onClick={() => setShowQuantity(314)}
                    >
                        Show All
                    </Button>
                </div>

                <Modal 
                    size="lg"
                    animation={true}
                    show={showOverlay} 
                    onHide={() => setShowOverlay(false)}


                >
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                        {renderTokenView(999, overlayID, 0, true)}
                    </Modal.Body>
                    
                </Modal>
            </Fragment>

        );

};