import React, { useState, useEffect } from 'react';
import Sketch from "react-p5";



const WavePoolAnimated = (props) => {

    let tokenID = props.poolID;
    let canvas;


    const [isInit, setIsInit] = useState(false);
    const [m, setM] = useState();


    let t = 0;
    let isPlaying = true;
    let isFirst = 0;
    let mask;
    let scaleFactor;



    const setup = (p, canvasParentRef) => {

        canvas = p.createCanvas(400, 400, p.P2D).parent(canvasParentRef);
        canvas.style('width', '100%');
        canvas.style('height', '');
        //smallP = p.createGraphics(400, 400);
        scaleFactor = 0.5;
        //p.print("Width: "+p.width);
        p.colorMode(p.RGB);
        p.frameRate(20);

        //console.log("Retrieving metadata from TEST DATA for ID: " + tokenID);
        if(!isInit)
            p.loadJSON("https://web3.autonomylab.xyz/meta/" + tokenID + ".json", j => initMoire(j, p));




        //noLoop();
    }
    const updateWithProps = (p) => {
        console.log(props);
        tokenID = props.tokenID;
        isPlaying = props.isPlaying;
        console.log("is playing: " + isPlaying);
        
        //p.print("Retrieving metadata from TEST DATA for ID: " + tokenID);
        if(!isInit)
            p.loadJSON("https://web3.autonomylab.xyz/meta/" + tokenID + ".json", initMoire);



    }

    function initMoire(json, _p){
        //console.log("HERE");
        //console.log(json);

        //mask = p.loadImage("https://ipfs.io/ipfs/QmY9AMvMKMNvpux5bnZqHApcryvPGCQHiRQD2cdbvFFwrs?filename=Nouveau1600.png");
        //mask.loadPixels();

        var attributes = json.attributes;
        //p.print(attributes);
        //p.print(p.int(attributes[0].layers));
        
        
        var layers = parseInt(attributes[0].value);
        var bgColor = '#'+(attributes[1].value);

        let mtemp = new Moire(tokenID, bgColor); 
        //p.print("layers: " + layers);

        for (var l = 0; l < layers; l++) {
            var dist = parseFloat(attributes[2 + (l * 7)]["value"]) * scaleFactor;
            var strokeWeight = parseFloat(attributes[3 + (l * 7)]["value"]) * scaleFactor;
            var rStart = parseFloat(attributes[4 + (l * 7)]["value"]);
            var rSpeed = parseFloat(attributes[5 + (l * 7)]["value"]);
            var curvePosition = parseFloat(attributes[6 + (l * 7)]["value"]);
            var curveAmount = parseFloat(attributes[7 + (l * 7)]["value"]) * scaleFactor;
            var layerColor;
            if(l < 2) layerColor = '#'+(attributes[8 + (0 * 7)]["value"]);
                else layerColor = '#'+(attributes[8 + (2 * 7)]["value"]);
            //p.print("Layer color " + layerColor);
            var newLayer = new MoireLayer(_p, dist, curveAmount, curvePosition, strokeWeight, rStart, rSpeed, layerColor);
            mtemp.addLayer(newLayer);
        }
        setIsInit(true);
        setM(mtemp)
        isFirst = 0;
        //console.log("Finished Init")
    }

    const draw = (p) =>  {

        //console.log('framerate: %d', p.frameRate())

        if (isInit) {
            if(isFirst < 10){
                m.draw(p, t);
                //p.image(p, 0, 0, 400, 400);
                isFirst++;
            }
            if(isPlaying){
                m.draw(p, t);
                //p.image(smallP, 0, 0, 400, 400);
                t++;
            }
            //p.print("frame:" +t);
        }
    }



    class Moire {


        constructor(_id, _colorBg) {
            this.id = _id;
            this.layers = [];
            this.colorBg = _colorBg;
            //p.print(this.colorBg);
        }
        
        addLayer(newLayer) {
            this.layers.push(newLayer);
            //p.print(newLayer);
        }
        
        
        draw(p, f) {
            //p.background('#00FF00');
            p.fill(this.colorBg)
            p.rect(0, 0, p.width, p.height)
            p.push();

            //p.print("Layers: " + this.layers);
            for (var i = 0; i < this.layers.length; i++) {
                var tempLayer = this.layers[i];
                //p.print("rendering " + this.id + " layer " + i);
                tempLayer.draw(p, f);
            }
            p.pop();
            p.push();
            p.noFill();
            p.stroke(this.colorBg);
            p.strokeWeight(350);
            p.circle(p.width*0.5, p.height*0.5, p.width * 1.68);

            p.randomSeed(10);
            //p.image(mask, 0, 0, 200, 200);
            //p.print("mask: " + mask);
            //p.blend(mask, 0, 0, 1600, 1600, p.random(-p.width, 0), p.random(-p.height, 0), p.width*2, p.height*2, p.MULTIPLY);
    

            p.pop();
    }
        
        
        
        }
        
    class MoireLayer{
        
        
        constructor(_p, _dist, _curveAmount, _curvePosition, _strokeWeight, _r, _rSpeed, _layerColor) {
            this.dist = _dist;
            this.strokeWeight = _strokeWeight;
            this.r = _r;
            this.rStart = _r;
            this.rSpeed = _rSpeed;
            this.curveAmount = _curveAmount;
            this.curvePosition = _curvePosition;
            this.layerColor = _layerColor;

            var g = _p.createGraphics(_p.width, _p.height);
            console.log(g.width);
            var lerpValue = g.lerp(-g.height, g.height, this.curvePosition);
            //g.beginDraw();
            g.push();
            g.stroke(this.layerColor);
            g.noFill();
            g.strokeWeight(this.strokeWeight);
            g.translate(g.width / 2.0, g.height / 2.0);
            //g.rotate(this.rStart)
            g.scale(1.2)

            for (var i = -g.width*2.0; i < g.width*2.0; i += this.dist) {
                g.beginShape();
                g.curveVertex(i, -g.height);
                g.curveVertex(i, -g.height);
                g.curveVertex(i + this.curveAmount, lerpValue);
                g.curveVertex(i, g.height);
                g.curveVertex(i, g.height);
                g.endShape();
        
            }
            //this.g.endDraw();
            g.pop();

            this.g = g;
            


        }
        
        
        
        draw(p, f) {
        
            p.push();
            // stroke(100, random(0, 255), random(0, 255));
            //p.stroke(this.layerColor);
            //p.noFill();
            //p.strokeWeight(this.strokeWeight);
            p.translate(p.width / 2.0, p.height / 2.0);
            //print(this.rStart + (f * (TWO_PI / this.rSpeed)));
            //p.rotate(p.QUARTER_PI)
            p.rotate(this.rStart + (f*this.rSpeed*0.01));
            //p.rotate(f);
            //p.scale(1.2);

            p.image(this.g, -p.width/2, -p.height/2);

            // var lerpValue = p.lerp(-p.height, p.height, this.curvePosition);
            
            // for (var i = -p.width*1.5; i < p.width*1.5; i += this.dist) {
            //     p.beginShape();
            //     p.curveVertex(i, -p.height);
            //     p.curveVertex(i, -p.height);
            //     p.curveVertex(i + this.curveAmount, lerpValue);
            //     p.curveVertex(i, p.height);
            //     p.curveVertex(i, p.height);
            //     p.endShape();
        
            // }
            p.pop();
        }
        
        }

    return <Sketch setup={setup} draw={draw}/>

}


export default React.memo(WavePoolAnimated);
    
