import React, { FC, useState, useCallback } from 'react';
import { useAnchorWallet, useConnection, useWallet } from '@solana/wallet-adapter-react';
import * as anchor from "@coral-xyz/anchor";
import { IDL, WavepoolsCreator } from "../idl/wavepools_creator"
import * as bs58 from "bs58";

//import { Textfit } from 'react-textfit';
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner';
import { BoxArrowUpRight } from 'react-bootstrap-icons';

import { DEFAULT_BREAKPOINTS } from 'react-bootstrap/esm/ThemeProvider';
import { BN } from 'bn.js';

import WavePoolAnimated from '../js/MoireGeneratorP5JSRev2'


const TOKEN_METADATA_PROGRAM_ID = new anchor.web3.PublicKey(
    "metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s"
  );



//import { connectedAddress } from '../Scripts/ClientWeb3.js'
//import WavePoolAnimated from '../Scripts/MoireGeneratorP5JSRev2'

export interface WavePoolViewProps{
    program: anchor.Program<WavepoolsCreator>
    connection: anchor.web3.Connection
    wavepoolID: number,
    bgColor: String,
    cardColor: String,
    mint: String,
    status: boolean,
    saleIsActive: boolean,
    salePriceSol?: number,
    showButtons: boolean,
    clickHandler: (a: number) => void
    mintSuccess: () => any
}

// For "Property 'solana' does not exist on type 'Window & typeof globalThis'" error.
interface Window {
    solana: any
  }
  declare var window: Window


export const WavePoolView: FC<WavePoolViewProps> = 
    (props) => {

    const [buyStatus, setBuyStatus] = useState(0);
    const [mintStatus, setMintStatus] = useState(0);

    
    //const { connection } = useConnection();

    const { publicKey, sendTransaction } = useWallet();

    let connectedAddress = publicKey?.toString();

    



    const getShortAddress = (address: String | undefined) => {
        if ( !address ) return "";
        var shortAddress = (address.substring(0, 3) + "..." + address.substring(38, 43))
        return shortAddress;
    }

    const resetState = () =>{
        setBuyStatus(0);
    }

    // componentDidMount(){
    //     this.setState({mintStatus: this.props.mintStatus});
    // }
    
    // componentDidUpdate(prevProps){
    //     if(prevProps.mintStatus != this.props.mintStatus)
    //         this.setState({mintStatus: this.props.mintStatus});
    // }

    const mintNFT = useCallback(async () => {

      if(!publicKey) return 0;
        
      console.log(publicKey.toString());
      console.log(process.env.REACT_APP_CREATOR_ADDRESS);
      console.log(process.env.REACT_APP_PROGRAM_ID);



      setMintStatus(1);


      const creatorPublicKey = new anchor.web3.PublicKey(process.env.REACT_APP_CREATOR_ADDRESS || "")
  
      //const program = new anchor.Program<WavepoolsCreator>(IDL, new anchor.web3.PublicKey(process.env.REACT_APP_PROGRAM_ID || ""), { connection})


      if(!publicKey) return;
      
      console.log(`minting wavepool #${props.wavepoolID}  to ${connectedAddress}`)

           // Derive the collectionMintAddress
    const collectionMintAddress = new anchor.web3.PublicKey(process.env.REACT_APP_COLLECTION_ADDRESS ?? "");
  
      // Derive the collectionMetadataAddress
      const collectionMetadataAddress = new anchor.web3.PublicKey(process.env.REACT_APP_COLLECTION_METADATA_ADDRESS ?? "")
  
      // Derive the collectionMasterEditionAddress
      const collectionMasterEdititonAddress = new anchor.web3.PublicKey(process.env.REACT_APP_COLLECTION_MASTER_EDITION_ADDRESS ?? "")
  
            // Derive the mint status PDA
      const mintStatusData = new anchor.web3.PublicKey(process.env.REACT_APP_MINT_STATUS_ADDRESS ?? "")
  
      


      const mintKeypair: anchor.web3.Keypair = anchor.web3.Keypair.generate()
    //   const mintAddress = (await anchor.web3.PublicKey.findProgramAddress(
    //     [
    //       Buffer.from("wavepool"),
    //       seedNumber.toBuffer('le', 2)
    //     ],
    //     props.program.programId
    //   ))[0];
    //   console.log(`New token mintAddress: ${mintAddress}`);

  
      const tokenAddress = await anchor.utils.token.associatedAddress({
        mint: mintKeypair.publicKey,
        owner: publicKey,
      });
      console.log(`New token associated account: ${tokenAddress}`);
  
      const metadataAddress = (await anchor.web3.PublicKey.findProgramAddress(
        [
          Buffer.from("metadata"),
          TOKEN_METADATA_PROGRAM_ID.toBuffer(),
          mintKeypair.publicKey.toBuffer(),
        ],
        TOKEN_METADATA_PROGRAM_ID
      ))[0];
      console.log(`New token metadata: ${metadataAddress}`);
  
      const masterEdititonAddress = (await anchor.web3.PublicKey.findProgramAddress(
        [
          Buffer.from("metadata"),
          TOKEN_METADATA_PROGRAM_ID.toBuffer(),
          mintKeypair.publicKey.toBuffer(),
          Buffer.from("edition"),
        ],
        TOKEN_METADATA_PROGRAM_ID
      ))[0];
      console.log(`New token master edition: ${masterEdititonAddress}`);
  
    
  

  
  
      const modifyComputeUnits = anchor.web3.ComputeBudgetProgram.setComputeUnitLimit({
        units: 300000
      })
  
      const transaction = await props.program.methods.mint (
        props.wavepoolID
      )
      .accounts({
  
        buyer: publicKey,
        creatorAccount: creatorPublicKey,
        mintStatus: mintStatusData,
  
        collectionMint: collectionMintAddress,
        collectionMetadata: collectionMetadataAddress,
        collectionMasterEdition: collectionMasterEdititonAddress,
  
        mint: mintKeypair.publicKey,
        tokenAccount: tokenAddress,
        metadata: metadataAddress,
        masterEdition: masterEdititonAddress,
  
  
        instructions: new anchor.web3.PublicKey("Sysvar1nstructions1111111111111111111111111"),
        tokenMetadataProgram: TOKEN_METADATA_PROGRAM_ID,
  
  
      })
      .preInstructions([modifyComputeUnits])
      .transaction();

    //await sendTransaction(transactionSimple, connection);
      try{
        transaction.recentBlockhash = (await props.connection.getLatestBlockhash("finalized")).blockhash;
    //   transaction.feePayer = publicKey;
    //   console.log(await transaction.getEstimatedFee(props.connection))

        const signature = await sendTransaction(
          transaction,
          props.connection,
          {signers: [mintKeypair]}
        );

        setMintStatus(1);

        await props.connection.confirmTransaction(signature,'processed');

        setMintStatus(2);

        await props.connection.confirmTransaction(signature,'confirmed');

        setMintStatus(0);
        props.mintSuccess()
        alert("Minting successful.")

  
        } catch (err: any){
          console.log(err);
          alert("User rejected the request.")
          setMintStatus(0);
        }
    }, [publicKey, sendTransaction, props.connection]);

  
    const renderMintButton = () => {
        // if(this.props.poolData.owner !== "0x0") {
        //     return;
        // }

        return(
            <div className="d-grid gap-1" >
                <Button 
                    variant={`outline-${props.bgColor == "000000" ? "light" : "dark"}`}
                    className="btn-block my-0 py-0 px-2"
                    disabled = {!props.saleIsActive}
                    // onClick = {e => {props.mintEthHandler(props.poolID)}}
                    onClick = {(e) => {setBuyStatus(1);}}
                >
                    {props.saleIsActive ? "Mint" : "Sale closed"}
                </Button>
            </div>
        );
    }

    const renderMintFinal = () => {
        return(
            <div className="d-grid gap-1" >
                    <Button 
                        variant={`outline-${props.bgColor == "000000" ? "light" : "dark"}`}
                        className="btn-block d-none"
                        disabled = {true}

                    >
                        {getShortAddress(connectedAddress)}
                    </Button>
                    <Button 
                        variant={`${props.bgColor == "000000" ? "light" : "dark"}`}
                        className="btn-block my-0 py-0 px-2"
                        style={{width: '100%'}}
                        disabled = {false}
                        onClick = {e => {mintNFT();}}
                    >
                        {props.salePriceSol || "N/A"} SOL
                    </Button>
            </div>
        );

    }


    const renderProcessing = () => {
        let displayString;
        switch (mintStatus){
            case 1: displayString = "confirm"; break;
            case 2: displayString = "minting"; break;
        }
        return(
            <div className="d-grid gap-1" >
                    <Button 
                        variant={`outline-${props.bgColor == "000000" ? "light" : "dark"}`}
                        className="btn-block d-none"
                        disabled = {true}
                    >
                        {getShortAddress(connectedAddress)}
                    </Button>
                    <Button 
                        variant='dark'
                        className="btn-block my-0 py-0 px-2"
                        style={{width: '100%'}}
                        disabled = {true}
                        
                    >
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                            &nbsp; {displayString} &nbsp;
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                    </Button>
            </div>
        )
    }

    const renderButtons = () => {


        if(!props.showButtons || props.status) return;
        if(mintStatus != 0) return renderProcessing();

        //let connectedAddressString = connectedAddress.toString().toLowerCase();
        //let ownerAddressString = this.props.poolData.owner.toString().toLowerCase();

        //if(connectedAddressString === ownerAddressString) return this.renderPlotButton();

        let n = 0;

        switch(buyStatus) {
            case 0:
                return renderMintButton();
            case 1:
                return renderMintFinal();
            }

      }

    // renderPlotButton(){
    //     if(this.props.mintStatus == 1) return this.renderMintingProcess();
    //     return(
    //         <div className="d-grid gap-1" >
    //                 <Button 
    //                     variant="outline-dark"
    //                     className="btn-block d-none"
    //                     disabled = {true}
    //                 >
    //                     {this.getShortAddress(connectedAddress)}
    //                 </Button>
    //                 <Button 
    //                     variant='dark'
    //                     style={{width: '100%'}}
    //                     disabled = {true}
    //                     onClick = {e => {this.props.buyPlotHandler(this.props.poolID);}}
    //                 >
    //                     Plots unlocked at 10% minted
    //                 </Button>
    //         </div>

    //     );
    // }

      const renderOwnership = () => {
        if(props.showButtons || props.status) return;
        let connectedAddressString = publicKey?.toString(); //connectedAddress.toString().toLowerCase();

        //if(parseInt(ownerAddressString, 16) == 0) return;

        let ownerAddressString = "UNKNOWN"

        let buttonString = "Mint"

        let onClick = (e: React.MouseEvent<HTMLElement>) => {
            e.preventDefault(); props.clickHandler(props.wavepoolID)
        }
        let href = "";
        let target = "";

        if(props.status) {
            buttonString = "Owned";
            onClick = () => {};
            href = `https://explorer.solana.com/address/${props.mint}?cluster=devnet`
            target = "_blank"
        }

        return(
                <div className="d-grid gap-1 my-0" >
                    <Button
                        variant={`outline-${props.bgColor == "000000" ? "light" : "dark"}`}
                        target={target}
                        onClick = {onClick}
                        className="btn-block my-0 py-0 px-2"
                        style={{float: "right"}}
                        href={href}
                    > 
                        {buttonString}
                    </Button >
                </div>   
        )

        
      }
    const renderView = (id: number) => {

        //let connectedAddressString = connectedAddress.toString().toLowerCase();
        //let ownerAddressString = this.props.poolData.owner.toString().toLowerCase();

        //let ownerAddressInt = parseInt(ownerAddressString, 16);

        if(!props.status){
            return (
   
                <Card.Img 
                    className="wavepool-image mb-2 rounded-0" 
                    src={"https://web3.autonomylab.xyz/img_small/" + id + ".png"}
                />
            )
        }else {
            return ( <WavePoolAnimated poolID={props.wavepoolID} /> );
        }

        
      }

    const render = (id: number) => {
        let style = {minWidth: '220px', flex: '1 0 15%', backgroundColor: `#${props.cardColor}`}
        //let owner = this.props.poolData.owner;
        //if(props.status) style = {minWidth: '300px', flex: '1 0 15%', backgroundColor: 'lightgray'}
        let titleStyle = {marginBottom: 0, fontWeight: "bold", color: "black"}
        if(props.bgColor == "000000") titleStyle = {marginBottom: 0, fontWeight: "bold", color: "#f7f7f7"}
        //console.log(props.bgColor)
        return (
            <Card 
                style={style}
                className="p-2 border-0"
                //onClick={e => {e.stopPropagation();}}
                onClick={e => {props.clickHandler(props.wavepoolID)}}
            >
                
                {renderView(id)}

                <Card.Body 
                        className="p-2" 

                        style={{
                            backgroundColor: `#${props.bgColor}`,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between"
                        
                        }}
                    >
                    <Card.Title 
                        style={titleStyle}

                    >
                            Wavepool #{id}
                    </Card.Title>
                
                
                {renderButtons()}
                {renderOwnership()}

                </Card.Body>

        

            </Card>
        )
    }

    return render(props.wavepoolID);

};

export default WavePoolView;